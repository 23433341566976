  <template>

  <div class="p-d-flex p-flex-column container">

    <div id="d1" style="display:none;z-index: 1000;position: relative;">
      <ModalAlwaysShow
        tipo='error'
        :messageErr="internalizationConsultCons.consultConsErrTimeOutLabel"
        :detailErr="internalizationConsultCons.consultConsErrTimeOutMsg"
      />
    </div>

    <div class="p-mb-2">
      <ImageFlexContainer align='left'/>
    </div>

    <div class="p-mb-2">
      <div class="p-d-flex p-jc-center button-container">
        <TecbanCardShadow 
          :cardLabel="internalizationConsultCons.consultConsIconMyConsLabel"
          :imgName="iconMeusConsentimentos"
          :viewName="aboutRoute"
        />
      </div>
    </div>

    <hr style="width:90%; margin-top: 2em; margin-bottom: 2em">

    <div class="p-mb-2">
      <div class="p-flex-column">
        <TecbanSimpleCardShadow 
          :cardLabel="internalizationConsultCons.consultConsFstBoxLabel"
          :msg="internalizationConsultCons.consultConsFstBoxLabel"
          @action="showAboutBox()"
        />
      </div>

      <div class="p-flex-column">
        <TecbanSimpleCardShadow 
          :cardLabel="internalizationConsultCons.consultConsSndBoxLabel"
          :msg="internalizationConsultCons.consultConsSndBoxLabel"
          @action="showDoubts()"
        />
      </div>
    </div>

    <Footer
      :footerText1="internalizationConsultCons.consultConsPortalCLabel"
      :footerText2="internalizationConsultCons.consultConsContactUsLabel"
      :action1="action2"
    />

  </div>
  
  <AboutBoxWrapper ref="aboutBox"></AboutBoxWrapper>

  <!--<ConditionsBox ref="conditionsBox"></ConditionsBox>-->

  <ExceptionModal
    ref="exceptionWarnig2"
    tipo='error'
    :messageErr="internalizationConsultCons.consultConsErrCommunicationFailLabel"
    :detailErr="internalizationConsultCons.consultConsErrCommunicationFailMsg"
  />

</template>

<script>
import { getInternalization, endSess } from '@/util/util.js';

/* Internalization, used if not use BD to get data 
var internalizationConsultConsTmp = {

  "consultConsIconMyConsLabel": "Meus Consentimentos de Pagamentos",
  "consultConsFstBoxLabel": "O que é Open Banking?",
  "consultConsSndBoxLabel": "Dúvidas Frequentes",

  "consultConsPortalCLabel": "Acessar Portal do Cidadão",
  "consultConsContactUsLabel": "Atendimento",

  "consultConsErrTimeOutLabel": "Tempo Excedido",
  "consultConsErrTimeOutMsg": "Você atingiu o limite de 05 minutos sem executar nenhuma ação.",

  "consultConsErrCommunicationFailLabel": "Falha na comunicação.",
  "consultConsErrCommunicationFailMsg": "Inicie a solicitação de consentimento novamente",

};
/* */

export default {
  data()  {
    return {
      iconMeusCompartilhamentos: 'meus-compartilhamentos',
      iconMeusConsentimentos: 'meus-consentimentos',
      aboutRoute: 'MyConsents',
      doubts: 'faq',
      contactUs:'contactUs',
      action2: 'contactUs',
      onLine:true,
    }
  },
  methods:  {
    test(message){
      console.log(message);
    },
    showAboutBox(){
      this.$refs.aboutBox.enable();
    },
    showDoubts(){
      this.$router.push({name: this.doubts });
    },
    showConditionsBox(){
      this.$refs.conditionsBox.enable();
    },
    goToView(){
      this.$router.push({name: this.contactUs });
    },
    checkOnline(){
      
      if(!navigator.onLine){
        endSess('dados')
        this.$refs.exceptionWarnig2.enable();
      }
    },
    defTimeOut(){

      var timeout;
      function refresh(){
        clearTimeout(timeout)

        timeout = setTimeout(() => {
          var el = document.getElementById("d1");
          el.style.display = "flex";
        },  300000);
      }

      refresh();
      document.addEventListener('click', refresh);

    }  
  },
  created(){

    this.internalizationConsultCons = getInternalization();

    setInterval(() => {
      this.checkOnline();
    }, 3000);

    this.defTimeOut();

  },
  mounted(){
    this.$root.$refs.mainNavbar.hideGoBackButton();
  }
}
</script>

<style scoped>
.container {
  background-color: var(--page-background-color);
  padding: 1em;
}
hr{
  background-color: var(--confcons-hr-color);
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  height: 1px;
  margin-top:0;
  margin-bottom: 10px;
}
</style>          